@import './assets/styles/reset.scss';
@import './assets/styles/vars.scss';

* {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

html,
body {
    position: relative;
    background: $gray;
    color: #fff;
    height: 100vh;
    width: 100vw;
}

#root {
    position: relative;
    height: 100%;
    width: 100%;
}

.loading-icon-new {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
