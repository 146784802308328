@import "../../assets/styles/vars.scss";

#appointments-window {
  height: 100%;
  overflow: auto;
  padding: 50px;
 
  .appointments-title {
    position: relative;
    z-index: 4005;
    border-bottom: 1px solid $curral;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    h6 {
      font-size: 26px;
      font-weight: 500;
      margin-right: 30px;
    }

    .ui-select-wrapper {
      width: 240px;
    }
  }

  .not_assign_leads_title {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
}
