@import "../../../assets/styles/vars.scss";

.button {
  display: block;
  width: 100%;
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  transition: 0.2s all;
  border: none;
  background: $curral;
  box-shadow: none;
  line-height: 1em;
  padding: 14px 12px;
  cursor: pointer;

  &:hover {
    background: darken($curral, 5%);
  }

  &:active {
    animation: buttonPress 0.2s ease-in-out;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none !important;
  }

  &:disabled {
    opacity: 0.45;
    cursor: default;

    &:hover {
      background: $curral;
    }
  }
}

@keyframes buttonPress {
  0% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}
