@import '../../assets/styles/vars.scss';

.navigation {
    flex: 0 0;
    padding: 26px 12px;
    background: #262626;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 10;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);

    .nav-logo {
        margin-bottom: 55px;

        img {
            max-height: 42px;
            max-width: 42px;
        }
    }

    .nav-lower {
        margin-top: auto;
    }

    .nav-item {
        cursor: pointer;
        text-align: center;

        svg {
            color: $curral;
            opacity: 0.4;
            transition: 0.2s all;
            font-size: 28px;
        }

        &:hover,
        &.active {
            svg {
                opacity: 1;
            }
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    // .sdr_nav_item {
    //     svg {
    //         color: #EE7C51;
    //         opacity: 1;
    //     }
    // }
}