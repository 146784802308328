@import "../../assets/styles/vars.scss";

#onboarding-window {
  overflow-y: auto;
  height: 100vh;

  .onboarding-width-control {
    max-width: 1055px;
    margin: 0 auto 0;
    padding: 50px 0 80px;
  }

  .onboarding-title {
    h1 {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 14px;
    }

    p {
      font-size: 18px;
      line-height: 1.4em;
      max-width: 830px;
      margin-bottom: 20px;
    }

    hr {
      height: 1px;
      width: 50vw;
      background: $curral;
      border: none;
      margin: 0 auto 0 0;
    }
  }

  .onboarding-steps {
    margin-top: 40px;

    .onboarding-step-numbers {
      position: relative;
      max-width: 850px;
      display: flex;
      align-content: center;
      justify-content: space-between;

      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 5px;
        right: 5px;
        transform: translateY(-50%);
        height: 3px;
        background: #84513d;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0) scale(1, 1);
      }

      .onboarding-step-number {
        position: relative;
        z-index: 2;
        height: 44px;
        width: 44px;
        background: #84513d;
        border-radius: 100%;
        overflow: hidden;
        display: flex;
        align-content: center;
        justify-content: center;
        color: #bb9f94;
        font-weight: 700;
        font-size: 20px;
        line-height: 44px;
        transition: 0.2s all;

        &.is-active,
        &.is-past {
          color: #fff;
          cursor: pointer;
          background: $curral;
        }
      }
    }
  }

  .onboarding-stage-wrapper {
    margin-top: 40px;

    .onboarding-stage-window {
      position: relative;
      opacity: 0;
      animation-name: slideInputsUp;
      animation-duration: 0.3s;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;

      @keyframes slideInputsUp {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      h1 {
        font-size: 22px;
        font-weight: 700;
        line-height: 1em;
        margin: 0 0 24px;
      }

      button {
        width: fit-content;
        padding: 12px 45px;
        font-size: 16px;
        margin: 50px 0 0;
      }
    }
  }

  .ob-input-block {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr;
    max-width: 725px;

    &.cols-2 {
      grid-template-columns: repeat(2, 1fr);
    }

    .span-2 {
      grid-column-end: span 2;
    }
  }
}

.onboarding-saving {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 130px);

  .large-check {
    position: relative;
    height: 165px;
    width: 165px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $curral;
    border-radius: 100%;
    overflow: hidden;

    svg {
      height: 50px;
      width: auto;
    }
  }

  h2 {
    font-size: 32px;
    font-weight: 600;
    margin: 45px 0 18px;
  }

  p {
    max-width: 480px;
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.5em;
  }

  hr {
    width: 330px;
    height: 1px;
    border: none;
    background: $curral;
    margin: 35px 0 15px;
  }

  .contact-details {
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 270px;

    a {
      color: $curral;
      transition: 0.2s all;

      &:hover {
        color: darken($curral, 5%);
      }
    }
  }
}
