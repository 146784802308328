@import "../../../assets/styles/vars.scss";

.inputfield {
  position: relative;

  label {
    display: none;
    position: absolute;
    font-size: 14px;
    padding: 0 10px;
    top: -6px;
    left: 5px;
    font-weight: 500;
    color: #727272;
    background: #262626;
  }

  input,
  textarea {
    width: 100%;
    font-size: 16px;
    color: #fff;
    transition: 0.2s all;
    border: 2px solid #3e3e3e;
    background: transparent;
    box-shadow: none;
    line-height: 1em;
    padding: 10px 12px;

    &::placeholder {
      font-size: 16px;
      color: #ababab;
      opacity: 0.65;
    }

    &:hover,
    &:active,
    &:focus,
    &.has-value {
      outline: none !important;

      &:not(:read-only) {
        border: 2px solid $curral;
      }
    }

    &:not(:placeholder-shown) {
      & + label {
        display: block;
      }
    }
  }

  textarea {
    height: 110px;
    resize: none;
    line-height: 1.4em;
  }

  small {
    display: block;
    margin-top: 8px;
    font-size: 14px;
  }
}

.input-error {
  display: block;
  margin-top: -6px;
  font-size: 14px;
  margin-bottom: 10px;
}

.rdt {
  margin-bottom: 16px;

  input {
    width: 100%;
    font-size: 16px;
    color: #fff;
    transition: 0.2s all;
    border: 2px solid #3e3e3e;
    background: transparent;
    box-shadow: none;
    line-height: 1em;
    padding: 10px 12px;

    &::placeholder {
      font-size: 16px;
      color: #ababab;
      opacity: 0.65;
    }

    &:hover,
    &:active,
    &:focus {
      outline: none !important;

      &:not(:read-only) {
        border: 2px solid $curral;
      }
    }
  }

  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
  }

  .rdtPicker {
    border: 0;
    background: $gray;
    top: 51px;
    padding: 0;

    td {
      &:hover {
        background-color: $curral !important;
      }
    }

    .rdtPrev,
    .rdtSwitch,
    .rdtNext,
    .rdtTimeToggle {
      vertical-align: middle;
    }

    .rdtActive {
      background-color: $curral;
    }

    .dow {
      font-size: 13px;
      opacity: 0.25;
      vertical-align: middle;
    }

    .rdtDay {
      vertical-align: middle;
    }
  }
}

.ib-select {
  user-select: none;
  background: #262626;

  .ib-select-wrap {
    position: relative;
    padding: 0;
    border: 2px solid #3e3e3e;
    transition: 0.2s all;

    &:focus,
    &:active,
    &:hover {
      border: 2px solid $curral;

      .ib-select-list {
        outline: none !important;
        border-left: 2px solid $curral;
        border-right: 2px solid $curral;
        border-bottom: 2px solid $curral;
      }

      .ib-select-input {
        svg {
          color: $curral;
          opacity: 1;
        }
      }
    }

    .ib-select-input {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 10px 12px;

      p {
        flex: 1 1;
        font-size: 16px;
        color: #ababab;
        opacity: 0.65;

        &.chosen {
          opacity: 1;
          color: #fff;
        }
      }

      svg {
        flex: 0 0;
        font-size: 16px;
        color: #ababab;
        opacity: 0.65;
        transition: 0.2s all;
      }
    }
  }

  .ib-select-list {
    position: absolute;
    width: calc(100% + 4px);
    left: -2px;
    z-index: 20;
    max-height: 0;
    overflow: hidden;
    transition: 0.2s all;
    border-left: 2px solid #3e3e3e;
    border-right: 2px solid #3e3e3e;
    border-bottom: 2px solid #3e3e3e;
    background: #262626;
    opacity: 0;
    pointer-events: none;

    &:focus,
    &:active,
    &:hover {
      outline: none !important;
      border-left: 2px solid $curral;
      border-right: 2px solid $curral;
      border-bottom: 2px solid $curral;
    }

    &.show {
      max-height: 175px;
      overflow-y: auto;
      padding: 0 5px 5px;
      opacity: 1;
      pointer-events: all;
    }

    .ib-select-option {
      display: flex;
      align-items: center;
      background: #242424;
      border-radius: 2px;
      padding: 8px 14px;
      color: #fff;
      opacity: 0.65;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      transition: 0.2s all;

      &:hover {
        color: $curral;
        opacity: 1;
        background: #181818;
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      a {
        padding: 5px 0;
        color: $curral;
        font-size: 14px;
        font-weight: 500;
        transition: 0.2s all;
      }
    }
  }
}

.ib-check-wrap {
  display: inline-flex;
  align-items: center;
  margin: 0 0 15px;
  cursor: pointer;
  position: relative;

  input,
  textarea {
    position: absolute;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    top: 0;

    &:checked {
      & ~ .checkbox {
        background-color: $curral;

        svg {
          transform: scale(1);
        }
      }
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 26px;
    height: 26px;
    width: 26px;
    transition: 0.2s all;
    border: 2px solid $curral;

    svg {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      transform: scale(0);
      transition: 0.2s all;
    }
  }

  label {
    flex: 1 1;
    margin-left: 12px;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    user-select: none;
  }
}
