// Colours
$success: #15CD72;
$error: #ED4F32;
$warning: #FFB347;
$info: #2A66BF;
$muted: #ECECEC;

$gray: #2E2E2E;
$grayDark: #262626;
$curral: #EE7C51;
$btnGray: #3e3e3e