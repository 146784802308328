@import "../../assets/styles/vars.scss";

#reporting-window {
  height: 100%;
  overflow: auto;
  padding: 50px;

  .report-title {
    p {
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  .report-client-select,
  .report-report-select {
    display: flex;
    width: 280px;
    align-items: center;

    .ib-select {
      flex: 1 1;
    }

    .reset {
      flex: 0 0;
      color: $curral;
      margin-left: 12px;
      cursor: pointer;
      user-select: none;
    }
  }

  .report-report-select {
    margin-top: 10px;
  }

  .report-fields {
    display: flex;
    margin-top: 20px;

    .report-field {
      width: 300px;

      &.small {
        width: 225px;
      }

      &:not(:last-child) {
        margin-right: 35px;
      }

      h6 {
        font-size: 14px;
        margin-bottom: 5px;
      }

      .fields {
        display: flex;

        .inputfield {
          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .download {
    color: $curral;
    margin: 20px 0;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
  }

  .report-wrap {
    width: 1128px;
    height: 824px;
    border: 2px solid #fff;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  }
}

.report-options {
  display: grid;
  grid-gap: 18px;
  align-items: center;
  grid-template-columns: 240px 55px 240px 55px;

  .clear-report-input {
    color: $curral;
    font-weight: 400;
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
      color: darken($curral, 5%);
    }
  }
}
