

.leads-count-container {
    display: flex;
    align-items: center;
    column-gap: 6px;
    margin-right: 14px;

    .count {
        font-size: 14px;
    }
}

.type-indicator {
    height: 15px;
    width: 15px;
    border-radius: 100%;
    margin-left: 7px;

    &.Appointment {
        background: #a7287d;
        box-shadow: 0 0 5px rgba(167, 40, 135, 0.6);
    }
    &.Appointment-noshow {
        background: #87CEFA;
        box-shadow: 0 0 5px rgba(167, 40, 135, 0.6);
    }

    &.Hot {
        background: #28A745;
        box-shadow: 0 0 5px rgba(40, 167, 69, 0.6);
    }

    &.Moderate {
        background: #FD7E14;
        box-shadow: 0 0 5px rgba(253, 126, 20, 0.6);
    }

    &.Low {
        background: #DC3545;
        box-shadow: 0 0 5px rgba(220, 53, 69, 0.6);
    }
}