@import '../../assets/styles/vars.scss';

#app-splash {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    // width: calc(100vw - 300px);
    // overflow: auto;
    overflow: hidden;


    #app-screen {
        flex: 1 1;
    }
}