@import '../../assets/styles/vars.scss';

#login-wrap {
    height: 100vh;
    width: 100vw;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    #login-loading {
        position: absolute;
        background: $gray;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #login-form-wrap {
        padding: 85px 50px;
        background: $grayDark;

        #login-logo {
            text-align: center;
            margin-bottom: 35px;

            img {
                max-width: 230px;
            }
        }

        #login-fields {
            width: 300px;

            .inputfield {
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }

        #password-reset {
            text-align: center;
            margin-top: 20px;

            p {
                cursor: pointer;
                color: $curral;
                font-size: 15px;
                font-weight: 500;
            }
        }

        #login-error {
            color: #e60202;
            font-size: 13px;
            font-weight: 400;
            line-height: 1.4em;
            text-align: center;
            max-width: 75%;
            margin: 0 auto 15px;
        }

        #password-reset-success {
            color: #02cd02;
            font-size: 13px;
            font-weight: 400;
            line-height: 1.4em;
            text-align: center;
            max-width: 75%;
            margin: 15px auto 0;
        }
    }
}