@import "../../../assets/styles/vars.scss";

#report {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #1a1919;
  width: 1124px;
  height: 820px;

  .report-bg {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 50px 25px 20px;
    background-color: #1a1919;
  }

  .top-left-img {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 300px;
  }

  .title {
    position: relative;
    z-index: 2;
    text-align: center;

    h1 {
      font-size: 32px;
      font-weight: 500;

      span {
        color: $curral;
      }
    }

    small {
      display: block;
      font-size: 12px;
      margin-top: 8px;
    }
  }

  .stats-table {
    margin: 35px 0 55px;
    text-align: center;

    table {
      margin: 0 auto;
      tr {
        td {
          text-align: left;
          font-size: 17px;
          padding-bottom: 5px;
          &:nth-child(2) {
            color: $curral;
            font-weight: 500;
            padding-left: 25px;
          }
        }
      }
    }
  }

  .pie-charts {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;

    .pc {
      h2 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }

  .profile_views {
    margin: auto 0;
    text-align: center;

    p {
      font-size: 18px;
      font-weight: 400;

      span {
        color: $curral;
        font-weight: 700;
      }
    }
  }

  .link {
    text-align: center;
    margin-bottom: 18px;

    p {
      font-size: 13px;
      margin-bottom: 5px;
    }

    small {
      color: $curral;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1a1919;

    .bar {
      width: 400px;
      height: 3px;
      background: $curral;
    }

    .logo {
      margin: 0 25px;

      img {
        height: 60px;
      }
    }
  }
}
