@import '../../assets/styles/vars.scss';

#clients-view {
    display: flex;
    height: 100%;
    width: 100%;

    .lead-list {
        overflow: auto !important;
        // overflow-y: auto;
        // overflow-x: visible;
        height: 100%;
       
        width:68vw;
        // width: 100%;
        flex: 1 1;
        padding: 30px;

        .add-toggles {
            display: flex;
            align-content: center;
            margin-bottom: 10px;
        }

        .lead-add {
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;
            // margin-bottom: 20px;

            &:not(:last-child) {
                margin-right: 20px;
            }

            svg {
                color: $curral;
                font-size: 16px;
            }

            p {
                font-size: 16px;
                font-weight: 500;
                margin-left: 10px;
            }
        }

        .client-name {
            p {
                font-size: 26px;
                font-weight: 500;
                margin-bottom: 20px;
            }
        }

        #leads-table {
            .lead-item {
                display: flex;
                width: 100%;
                align-items: center;
                padding: 14px 16px;
                background: #262626;
                cursor: pointer;

                &:not(:last-of-type) {
                    margin-bottom: 4px;
                }

                p {
                    font-size: 14px;
                }

                .li {
                    user-select: none;
                    // min-width: max-content;
                    // min-width: 30ch;

                    &.date {
                        flex: 0 0 95px;
                    }

                    &.type {
                        flex: 0 0 40px;
                        display: flex;
                        align-items: center;

                        svg {
                            margin-left: 5.4px;

                            path {
                                font-size: 15px;
                                color: #FFD700;
                                text-shadow: 0 0 5px rgba(255, 215, 0, 0.6);
                            }
                        }
                    }

                    .type-indicator {
                        height: 15px;
                        width: 15px;
                        border-radius: 100%;
                        margin-left: 7px;

                        &.Appointment {
                            background: #a7287d;
                            box-shadow: 0 0 5px rgba(167, 40, 135, 0.6);
                        }
                        &.Appointment-noshow {
                            background: #87CEFA;
                            box-shadow: 0 0 5px rgba(167, 40, 135, 0.6);
                        }

                        &.Hot {
                            background: #28A745;
                            box-shadow: 0 0 5px rgba(40, 167, 69, 0.6);
                        }

                        &.Moderate {
                            background: #FD7E14;
                            box-shadow: 0 0 5px rgba(253, 126, 20, 0.6);
                        }

                        &.Low {
                            background: #DC3545;
                            box-shadow: 0 0 5px rgba(220, 53, 69, 0.6);
                        }
                    }

                    &.name {
                        
                        // width: fit-content;
                        overflow: hidden;
                        min-width: 11vw; 
                        text-overflow: ellipsis;
                        // background-color: orange;
                        display: -webkit-box;
                        -webkit-line-clamp: 1; /* number of lines to show */
                                line-clamp: 1; 
                        -webkit-box-orient: vertical;
                        padding-right: 25px;
                    }

                    &.job {
                       
                        //  background-color:blue;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         min-width: 18vw;
                         display: -webkit-box;
                         -webkit-line-clamp: 1; /* number of lines to show */
                                 line-clamp: 1; 
                         -webkit-box-orient: vertical;
                        padding-right: 25px;
                        
                    }

                    &.company {
                        
                        // background-color:orange;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        min-width: 15vw;
                        display: -webkit-box;
                        -webkit-line-clamp: 1; /* number of lines to show */
                                line-clamp: 1; 
                        -webkit-box-orient: vertical;
                        padding-right: 25px;
                        
                    }

                    &.init_msg {
                        
                       
                        // background-color: #28A745;
                        overflow: hidden;
                        text-overflow: ellipsis;
                       min-width: 22vw;
                        display: -webkit-box;
                        -webkit-line-clamp: 1; /* number of lines to show */
                                line-clamp: 1; 
                        -webkit-box-orient: vertical;
                        padding-right: 25px;
                        

                     
                       
                    }

                    &.toggle {
                        margin-left: auto;

                        svg {
                            color: $curral;
                        }
                    }
                }
            }
        }
    }
}

.lead-added-by {
    font-size: 15px;
    margin: 12px 0 15px;

    span {
        color: $curral;
    }
}

.stakeholder-item {
    position: relative;
    margin-top: 18px;

    .stakeholder-name {
        display: flex;
        align-items: center;

        p {
            font-size: 16px;
        }

        svg {
            color: $curral;
            margin-left: 5px;
        }
    }

    .stakeholder-email {
        margin-top: 5px;

        p {
            color: #FFF;
            opacity: 0.4;
            font-size: 14px;
        }
    }

    .stakeholder-remove {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        padding: 6px 8px;
        border-radius: 2px;
        transition: 0.2s all;
        cursor: pointer;
        border: 1px solid transparent;

        svg {
            color: $curral;
        }

        &:hover {
            background: #292929;
            border: 1px solid #1d1d1d;
        }
    }
}

.engagement-item {
    display: flex;
    margin-top: 18px;

    .engagement-date {
        flex: 0 0 90px;
        text-align: right;
        margin-right: 15px;
        color: #7c7c7c;

        p {
            font-size: 15px;
            font-weight: 500;
        }

        small {
            font-size: 13px;
            font-weight: 500;
        }
    }

    .engagement-details {
        padding-right: 12px;

        h6 {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.2em;
            margin-bottom: 6px;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2em;
        }
    }

    .action-remove {
        display: block;
        margin-left: auto;
        padding: 6px 8px;
        border-radius: 2px;
        transition: 0.2s all;
        cursor: pointer;
        border: 1px solid transparent;
        height: fit-content;

        svg {
            color: $curral;
        }

        &:hover {
            background: #292929;
            border: 1px solid #1d1d1d;
        }
    }
}

.sdr-form {
    display: none;

    &.active {
        display: block;
        margin-bottom: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid $curral;
    }
}