@import "../../../assets/styles/vars.scss";

.ui-select-wrapper {
  position: relative;

  &.has-error {
    .ui-select-upper {
      border: 2px solid red;
    }

    small {
      color: red;
    }
  }

  &.dropdown-showing {
    .ui-select-upper {
      &:not(.no-styles) {
        background: #2e2e2e;
        border: 2px solid $curral;
      }

      .ui-input-wrapper {
        .ui-input-block {
          .ui-input-text {
            border: 2px solid $curral !important;
          }
        }
      }
    }
  }

  .ui-input-upper-placeholder {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 14px;
    font-weight: 500;
    color: #ababab;
    transition: 0.25s all;
    opacity: 0;
    padding: 3px 6px;
    pointer-events: none;

    &.active {
      top: 0px;
      left: 14px;
      opacity: 1;
      font-size: 12px;
      background: #fff;
    }
  }

  .ui-select-upper {
    transition: 0.2s all;
    min-height: 39px;
    cursor: pointer;

    &:not(.no-styles) {
      position: relative;
      padding: 9px 18px;
      background: #2e2e2e;
      border: 2px solid #4b4b4b;
    }

    &.dropdown-showing {
      .ui-select-icon {
        svg {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &:hover,
    &.has-value {
      &:not(.no-styles) {
        background: #2e2e2e;
        border: 2px solid $curral;
      }

      .ui-input-wrapper {
        .ui-input-block {
          .ui-input-text {
            border: 2px solid $curral !important;
          }
        }
      }
    }

    .ui-input-wrapper {
      .ui-input-block {
        .ui-input-text {
          &:focus,
          &:active,
          &:not(:placeholder-shown) {
            border: 2px solid $curral;
          }
        }
      }
    }

    .ui-select-placeholder {
      position: relative;
      font-weight: 400;
      min-height: 19px;
      line-height: 19px;
      font-size: 16px;
      color: #ababab;
      opacity: 0.65;

      .ui-multiple-selected-options-row {
        position: absolute;
        z-index: 2;
        display: flex;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        align-items: center;

        .ui-multiple-selected-option {
          color: #fff;
          background: #515151;
          padding: 4px 6px;
          border-radius: 3px;
          font-size: 12px;
          transition: 0.2s all;
          white-space: nowrap;

          &.is-hidden {
            opacity: 0;
            pointer-events: none;
            position: absolute;
          }

          &:hover {
            background: darken(#515151, 5%);
          }

          &:not(:last-child) {
            margin-right: 5px;
          }

          &:not(.is-total) {
            &:after {
              content: "x";
              padding: 3px 0 2px 5px;
              margin-left: 5px;
              border-left: 1px solid #707070;
            }
          }
        }
      }
    }

    .ui-select-icon {
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translateY(-50%);

      svg {
        position: absolute;
        top: 50%;
        height: 10px;
        transform: translateY(-50%);
        transition: 0.2s all;
      }
    }
  }

  .ui-select-dropdown {
    position: absolute;
    z-index: 1005;
    background: #2e2e2e;
    max-height: 0;
    overflow: hidden;
    top: 100%;
    width: 100%;

    &.is-showing {
      max-height: 200px;
      overflow-y: auto;
      border-left: 2px solid $curral;
      border-right: 2px solid $curral;
      border-bottom: 2px solid $curral;
    }

    .ui-multiple-select-option {
      font-size: 14px;
      font-weight: 400;
      padding: 8px 18px;

      .ui-checkbox {
        width: 100%;
      }
    }

    .ui-select-option {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      padding: 8px 18px;
      cursor: pointer;
      background: #2e2e2e;
      transition: 0.2s all;

      &.is-active,
      &:hover {
        color: #fff;
        background: $curral;
      }
    }
  }

  small {
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 1em;
    margin: 5px 0 0;
    color: #5d6373;
  }
}
