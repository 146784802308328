#error-holder {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    user-select: none;

    .error-title {
        font-size: 200px;
        font-weight: 900;
    }

    .error-subtitle {
        font-size: 22px;
        font-weight: 600;
        margin-top: 20px;
    }
}