@import '../../assets/styles/vars.scss';

.modal {
    position: fixed;
    z-index: 20;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    transition: 0.2s all;
    opacity: 0;
    pointer-events: none;

    &.active {
        background: rgba(0, 0, 0, 0.6);
        opacity: 1;
        pointer-events: all;

        .modal-content {
            left: 0;
        }
    }

    .modal-content {
        position: absolute;
        height: 100%;
        left: -450px;
        width: 450px;
        transition: 0.2s all;
        background: #262626;
        padding: 40px 35px;
        overflow-y: auto;

        .modal-close {
            position: absolute;
            top: 20px;
            right: 25px;
            display: inline;
            cursor: pointer;

            svg {
                font-size: 32px;
                color: $curral;
            }
        }

        h1 {
            font-size: 28px;
            font-weight: 600;
            margin-bottom: 28px;
            user-select: none;
        }

        .modal-nav {
            display: flex;
            user-select: none;
            margin-bottom: 20px;

            p {
                color: $curral;
                font-size: 14px;
                opacity: 0.55;
                transition: 0.2s all;
                cursor: pointer;

                &:not(:last-child) {
                    margin-right: 30px;
                }

                &:hover,
                &.active {
                    opacity: 1;
                }
            }
        }

        .modal-form {
            pointer-events: none;
            position: relative;
            opacity: 0;
            max-height: 0;
            overflow: hidden;

            .inputfield {
                margin-bottom: 16px;
            }

            &.show {
                pointer-events: all;
                opacity: 1;
                max-height: 999px;
                overflow: visible;
            }

            &.engagement-form {
                &.show {
                    overflow: visible;
                }
            }

            .adding-error {
                color: #e60202;
                font-size: 13px;
                font-weight: 400;
                line-height: 1.4em;
                text-align: center;
                margin: 15px 0 0;
            }

            .react-datepicker-wrapper {
                width: 100%;
                cursor: pointer;

                input {
                    cursor: pointer;
                }
            }
        }
    }
}