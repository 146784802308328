@import "../../../assets/styles/vars.scss";

.lead-list-block {
  margin-bottom: 30px;

  .lead-list-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    p {
      color: $curral;
      font-size: 14px;
      margin-right: 20px;
    }

    hr {
      flex: 1 1 auto;
      background: $curral;
      height: 1px;
      border: none;
    }
  }
}

.leads_icon {
  flex: 0 0 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterBtn {
  color: white;
  background: $btnGray;
  padding: 11px 30px;
  cursor: pointer;

  .filterBtn:hover {
    background: $grayDark;
  }
}

.downloadbtn {
  // width:"50px";
  color: $curral;
  margin-right: 20px;
  // background: $btnGray;
  // padding: 0px 15px 0px 0px;
  cursor: pointer;

  .filterBtn:hover {
    background: $grayDark;
  }
}

@media screen and (max-width: 1319px) {
  .filterBtn {
    padding: 11px 50px;
  }
}

@media screen and (max-width: 1260px) {
  .filterBtn {
    padding: 11px 40px;
  }
}

.load-more-months {
  width: fit-content;
  padding: 12px 26px;
  margin: 20px auto 0;
}

.text-Over-name {
  overflow: hidden;
  width: 8vw;
  margin-left: 20px auto 0;
  min-width: fit-content;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;


}

.text-Over-flow {
  overflow: hidden;
  width: 8vw;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;


}

.text-Over-flow-company {
  overflow: hidden;
  width: 8vw;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;


}

.text-Over-flow-message {
  overflow: hidden;
  width: 20vw;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;


}

.lead-item {
  height: 54px;
}