@import "../../../assets/styles/vars.scss";

.client-row {
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .client-name {
    flex: 0 0 180px;
    margin-right: 20px;
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .client-rank {
    flex: 0 0 40px;
    margin-right: 14px;
    color: #EE7C51;
    font-size: 20px;
    // font-weight: 500;
  }

  .text-color-orange {

  }

  .client-rank-icon {
    flex: 0 0 30px;
    margin-right: 10px;
  }

  .rank-display {
    font-size: 20px;
  }

  .kpi-progress {
    position: relative;
    height: 50px;
    flex: 1 1 auto;
    background: #3b3b3b;

    .lead-breakdown {
      position: absolute;
      z-index: 2005;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(59, 59, 59, 0.9);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: 0.2s all;

      &:hover {
        opacity: 1;
      }

      p {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        margin: 0;

        &:not(:last-of-type) {
          margin: 0 35px 0 0;
        }
      }
    }

    .kpi-progress-bar {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;

      p {
        font-size: 18px;
        font-weight: 500;
      }

      &.green {
        background: #28a745;
      }

      &.amber {
        background: #fd7e14;
      }

      &.red {
        background: #dc3545;
      }
    }

    .kpi-target {
      position: absolute;
      font-weight: 500;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.6;
      font-size: 18px;
    }

    .no-kpi-value {
      position: absolute;
      font-weight: 500;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
    }
  }
}
