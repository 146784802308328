@import "../../assets/styles/vars.scss";

#settings-window {
  h1 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  hr {
    height: 1px;
    width: 50vw;
    background: $curral;
    border: none;
    margin: 0 auto 0 0;
  }

  .add-user-account {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    max-width: 325px;
    margin-top: 40px;

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1em;
      margin-bottom: 2px;
    }
  }
}

#settings-window {
  height: 100%;
  overflow: auto;
  padding: 50px;

  .settings-title,
  .delete-title {
    border-bottom: 1px solid $curral;
    margin-bottom: 10px;

    p {
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }
  .delete-title {
    margin-top: 20px;
  }

  #register-fields,
  #delete-fields {
    width: 300px;

    .inputfield {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    #login-error {
      color: #e60202;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.4em;
      text-align: center;
      max-width: 75%;
      margin: 0 auto 15px;
    }
  }
}
