.input-with-icon {
    position: relative;
    width: 200px;

    .input {
        width: 100%;
        background-color: #3e3e3e;
        padding: 7px 10px; // Remove the padding reserved for the icon
        font-size: 16px;
        border: 1px solid #504f4f;
        border-radius: 2px;
        outline: none;
        color: #FFF;
        padding-left: 34px; // Add padding for the icon space

        &:focus {
            border-color: #9e9d9d;
        }
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        font-size: 18px;
        color: #ccc;
        pointer-events: none; // Prevent interaction with the icon
    }
}

.suggestions-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #262626;
    border: 1px solid #262626;
    border-top: none;
    z-index: 1000;
    max-height: 400px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0;

    .suggestion-item {
        padding: 18px 14px;
        cursor: pointer;
        font-size: 16px;
        color: #FFF;
        display: flex;
        gap: 10px;

        &:hover {
            background-color: #504f4f;
        }
    }
}
